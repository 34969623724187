import React, { useState } from 'react';
import { Form, Input, Button, Modal, Card, Tooltip, Descriptions } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Education = ({ value = [], onChange }) => {
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [modalTitle, setModalTitle] = useState('添加');

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      md: {
        offset: 5,
        span: 16,
      },
      xs: {
        offset: 0,
        span: 16,
      }
    },
  };

  const triggerChange = changedValue => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  // 点击添加按钮
  const handleBtnClick = () => {
    form.resetFields();
    setShowForm(true);
  };
  // 关闭弹层
  const handleCancel = () => {
    setShowForm(false);
  };

  // 添加
  const onFinish = formData => {
    let newEduRecords = value;

    if (!formData.id) {
      formData.id = Math.random();
      newEduRecords.push(formData);
    } else {
      for (let i = 0; i < newEduRecords.length; i++) {
        if (newEduRecords[i].id === formData.id) {
          newEduRecords[i] = formData;
        }
      }
    }
    setShowForm(false);
    triggerChange([...newEduRecords]);
  };

  // 表单验证失败
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  // 编辑
  const handleEditForm = id => {
    const [formData] = value.filter(item => item.id === id);
    setModalTitle('修改');
    form.setFieldsValue(formData);
    setShowForm(true);
  };

  // 删除
  const handleDelForm = id => {
    const newEduRecords = value.filter(item => item.id !== id);
    triggerChange([...newEduRecords]);
  };

  // 操作按钮
  const genOpBtn = id => (
    <>
      <Tooltip title="修改">
        <Button type="link" icon={<EditOutlined />} onClick={() => handleEditForm(id)} />
      </Tooltip>
      <Tooltip title="删除">
        <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDelForm(id)} />
      </Tooltip>
    </>
  );

  const eduList = value.map(item => (
    <Card
      hoverable
      size="small"
      title={item.eduTime}
      key={item.id}
      extra={genOpBtn(item.id)}
      style={{ marginTop: 5 }}
    >
      <Descriptions>
        <Descriptions.Item label="学校名称">{item.schoolName}</Descriptions.Item>
        <Descriptions.Item label="专业">{item.course}</Descriptions.Item>
        <Descriptions.Item label="学历">{item.degree}</Descriptions.Item>
      </Descriptions>
    </Card>
  ));

  return (
    <span>
      <Button type="primary" icon={<PlusOutlined />} onClick={handleBtnClick}>
        添加
      </Button>
      <Modal
        title={modalTitle}
        visible={showForm}
        destroyOnClose
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          form={form}
          name="edu-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="id"
            name="id"
            hidden
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="起始时间"
            name="eduTime"
            rules={[
              {
                required: true,
                message: '请填写起始时间~',
              },
            ]}
          >
            <Input placeholder="2011年9月-2019年12月" />
          </Form.Item>

          <Form.Item
            label="学校名称"
            name="schoolName"
            rules={[
              {
                required: true,
                message: '请填写学校名称~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="专业"
            name="course"
            rules={[
              {
                required: true,
                message: '请填写专业~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="学历"
            name="degree"
            rules={[
              {
                required: true,
                message: '请填写学历~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              确定
        </Button>
          </Form.Item>
        </Form>
      </Modal>
      {eduList}
    </span>
  );
};

export default Education;