import React, {useEffect, useState} from 'react';
import { Layout, Form, Input, Button, Checkbox, Radio, Row, Col, Select, DatePicker, message, Result } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import Job from './components/Job';
import Education from './components/Education';
import './App.css';

const dateFormat = 'YYYY/MM/DD';
const { Content } = Layout;

const App = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitSuc, setSubmitSuc] = useState(false);
  // const phoneNumReg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;

  useEffect(() => {
    let formData = window.localStorage.getItem('formData');
    // 有值代表不是第一次访问或者是已经成功提交
    if (formData) {
      formData = JSON.parse(formData);
      if (formData.birthday) {
        formData.birthday = moment(formData.birthday);
      }
      if (formData.attTime) {
        formData.attTime = moment(formData.attTime);
      }

      form.setFieldsValue(formData);
    }
  }, []);

  const getHost = () => {
    let isPe = window.location.host.includes('hr.biddingx.com');

    if (isPe) {
      return 'https://managerx.biddingx.com';
    } else {
      // return 'http://localhost:3000';
      // return 'http://new.new.test.biddingx.com';
      return 'http://dsp.aio.k8s.sf';
    }
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 10,
    },
  };
  const tailLayout = {
    wrapperCol: {
      lg: {
        offset: 8,
        span: 16
      },
      xs: {
        offset: 0,
        span: 24
      }
    },
  };
  const getFormatData = data => {
    data.birthday = Math.floor(new Date(data.birthday).getTime() / 1000);
    data.attTime = Math.floor(new Date(data.attTime).getTime() / 1000);
    data.huji = [data.huji.locate, data.huji.town];

    return data;
  };
  // 提交
  const onFinish = values => {
    const formatedData = getFormatData(values);
    const host = getHost();
    setLoading(true);

    const xhr = new window.XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        const res = JSON.parse(xhr.response);
        if (!res.success && res.code) {
          message.warning('提交失败: ' + res.message, 5);
        } else {
          message.success('提交成功～');
          setSubmitSuc(true);
          window.localStorage.removeItem('formData');
        }
        setLoading(false);
      }
    };
    xhr.onerror = function (err) {
      message.warning(JSON.stringify(err), 5);
    };

    xhr.open("POST", host + "/api/admin/candidate/add");
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.send('data=' + encodeURIComponent(JSON.stringify(formatedData)));
  };
  // 验证失败
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const onValuesChange = (change, all = {}) => {
    const newFormData = {...all};
    window.localStorage.setItem('formData', JSON.stringify({...newFormData}));
  };
  const handleSubmitClick = () => {
    const host = getHost();
    const xhr = new window.XMLHttpRequest();
    xhr.open('GET', host + '/api/admin/candidate/collect?a=1');
    xhr.send();
  };
  // 验证户籍性质
  const checkHuji = (_, value) => {
    return Promise.resolve();
  };
  // 验证工作经验
  const checkJobs = (_, value) => {
    if (value && value.length) {
      return Promise.resolve();
    }
    return Promise.reject('请至少填写一份工作经历~');
  };
  // 验证学历背景
  const checkEdus = (_, value) => {
    if (value && value.length) {
      return Promise.resolve();
    }
    return Promise.reject('请至少填写一份学历背景~');
  };
  // 户籍性质选择
  const hujiRadio = (
    <>
      <Form.Item
        name={['huji', 'locate']}
        noStyle
        rules={[{
          required: true,
          message: '请选择本地或异地~'
        }]}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={1}>本地</Radio.Button>
          <Radio.Button value={2}>异地</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={['huji', 'town']}
        noStyle
        rules={[{
          required: true,
          message: '请选择农村或城市~'
        }]}
      >
        <Radio.Group buttonStyle="solid" style={{ marginLeft: 10 }}>
          <Radio.Button value={3}>农村</Radio.Button>
          <Radio.Button value={4}>城市</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </>
  );
  // 看重点
  const whatFors = [
    '职业发展前景',
    '薪酬激励',
    '福利保障',
    '工作地点',
    '工作稳定',
    '专业对口',
    '企业的发展前景',
    '企业的市场口碑',
    '行业发展前景',
    '工作环境是否舒适',
    '工作强度，是否加班',
    '企业是否即将上市',
    '团队成员能力水平',
    '持续的学习机会',
    '做自己想做的产品',
    '技术上的精进',
    '工作时间弹性',
    '主管领导风格',
    '团队工作氛围',
  ];
  const genWhatForCheckbox = () => {
    return (
      <Checkbox.Group style={{ width: '100%' }}>
        <Row>
          {whatFors.map((item, i) => {
            return (
              <Col span={12} key={item}>
                <Checkbox value={i + 1}>{item}</Checkbox>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    );
  };

  return (
    <Layout>
      {submitSuc ? (
        <Result
          status="success"
          title="提交成功～"
        />
      ) : (
          <>
            <Content>
              <div className="site-layout-background" style={{ padding: '0 5px' }}>
                <Row>
                  <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
                    <Form
                      {...layout}
                      form={form}
                      name="candidate-form"
                      className="form"
                      onFinish={onFinish}
                      initialValues={{}}
                      onFinishFailed={onFinishFailed}
                      onValuesChange={onValuesChange}
                    >
                      <Form.Item noStyle>
                        <h1 className="header form-header">
                          个人信息登记
                  </h1>
                      </Form.Item>

                      <Form.Item
                        label="姓名"
                        name="userName"
                        rules={[
                          {
                            required: true,
                            max: 30,
                            whitespace: true,
                            message: '请填写姓名～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="职位"
                        name="job"
                        rules={[
                          {
                            required: true,
                            max: 70,
                            whitespace: true,
                            message: '请填写职位~',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="手机号码"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            // pattern: phoneNumReg,
                            len: 11,
                            message: '请填写正确的手机号码～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="微信"
                        name="wechat"
                        rules={[
                          {
                            required: true,
                            max: 70,
                            whitespace: true,
                            message: '请填写微信～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            max: 70,
                            message: '请填写正确的E-mail～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="英文名"
                        name="userNameEN"
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="性别"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: '请选择性别～',
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value={1}>女</Select.Option>
                          <Select.Option value={2}>男</Select.Option>
                          <Select.Option value={0}>其他</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="出生日期"
                        name="birthday"
                        rules={[
                          {
                            required: true,
                            message: '请选择出生日期～',
                          },
                        ]}
                      >
                        <DatePicker
                          inputReadOnly
                          locale={locale}
                          format={dateFormat}
                          showToday={false}
                          disabledDate={date => date > Date.now()}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="民族"
                        name="nation"
                        rules={[
                          {
                            required: true,
                            max: 30,
                            whitespace: true,
                            message: '请填写民族～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="户籍性质"
                        name="huji"
                        rules={[
                          {
                            required: true,
                            validator: checkHuji,
                          }
                        ]}
                      >
                        {hujiRadio}
                      </Form.Item>

                      <Form.Item
                        label="当前住址"
                        name="addr"
                        rules={[
                          {
                            required: true,
                            max: 250,
                            whitespace: true,
                            message: '请填写当前住址～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="工作履历"
                        name="jobs"
                        rules={[
                          {
                            required: true,
                            validator: checkJobs
                          }
                        ]}
                      >
                        <Job />
                      </Form.Item>

                      <Form.Item
                        label="学历背景"
                        name="edus"
                        rules={[
                          {
                            required: true,
                            validator: checkEdus
                          },
                        ]}
                      >
                        <Education />
                      </Form.Item>

                      <Form.Item
                        label="薪资期望"
                        name="salary"
                        rules={[
                          {
                            required: true,
                            max: 100,
                            whitespace: true,
                            message: '请填写薪资期望～',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="最快到岗时间"
                        name="attTime"
                        rules={[
                          {
                            required: true,
                            message: '请选择到岗时间～',
                          },
                        ]}
                      >
                        <DatePicker
                          inputReadOnly
                          locale={locale}
                          format={dateFormat}
                          showToday={false}
                          disabledDate={date => date < Date.now()}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="是否已与前公司解除劳动关系"
                        name="isReleased"
                        rules={[
                          {
                            required: true,
                            message: '请选择是否已解除劳动关系～',
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={1}>是</Radio.Button>
                          <Radio.Button value={2}>否</Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label="是否签有竞业协议"
                        name="isSign"
                        rules={[
                          {
                            required: true,
                            message: '请选择是否签有竞业协议～',
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={1}>是</Radio.Button>
                          <Radio.Button value={2}>否</Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label="是否接受背景调查"
                        name="allowCheck"
                        rules={[
                          {
                            required: true,
                            message: '请选择是否接受背景调查～',
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={1}>是</Radio.Button>
                          <Radio.Button value={2}>否</Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        label="是否有亲属或朋友在本公司"
                        name="hasRelatives"
                        rules={[
                          {
                            required: true,
                            message: '请选择是否有亲属或朋友在本公司～',
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value={1}>是</Radio.Button>
                          <Radio.Button value={2}>否</Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) => prevValues.hasRelatives !== currentValues.hasRelatives}
                      >
                        {({ getFieldValue }) => {
                          switch (getFieldValue('hasRelatives')) {
                            case 1:
                              return ([
                                    <Form.Item
                                        name="relativesDepartment"
                                        label="服务部门"
                                        rules={[
                                          {
                                            required: true,
                                            message: '请填写服务部门～'
                                          }
                                        ]}>
                                      <Input />
                                    </Form.Item>,
                                    <Form.Item
                                        name="relativesName"
                                        label="姓名"
                                        rules={[
                                          {
                                            required: true,
                                            message: '请填写姓名~'
                                          }
                                        ]}>
                                      <Input />
                                    </Form.Item>
                              ]);
                            case 2:
                              return null;
                            default:
                              return null;
                          }
                        }}
                      </Form.Item>

                      <Form.Item
                        label="简历投递途径"
                        name="where"
                        rules={[
                          {
                            required: true,
                            message: '请填写简历投递途径～',
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value={1}>舜飞主动联系</Select.Option>
                          <Select.Option value={2}>网站</Select.Option>
                          <Select.Option value={3}>APP</Select.Option>
                          <Select.Option value={4}>朋友推荐</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.where !== currentValues.where}
                      >
                        {({ getFieldValue }) => {
                          switch (getFieldValue('where')) {
                            case 1:
                              return null;
                            case 2:
                              return (
                                <Form.Item
                                  name="customWhere"
                                  label="哪个网址"
                                  rules={[
                                    {
                                      required: true,
                                      max: 100,
                                      whitespace: true,
                                      message: '请填写网址～'
                                    }
                                  ]}>
                                  <Input />
                                </Form.Item>
                              );
                            case 3:
                              return (
                                <Form.Item
                                  name="customWhere"
                                  label="哪个APP"
                                  rules={[
                                    {
                                      required: true,
                                      max: 100,
                                      whitespace: true,
                                      message: '请填写APP~'
                                    }
                                  ]}>
                                  <Input />
                                </Form.Item>
                              );
                            case 4:
                              return (
                                <Form.Item
                                  name="customWhere"
                                  label="介绍人"
                                  rules={[
                                    {
                                      required: true,
                                      max: 100,
                                      whitespace: true,
                                      message: '请填写介绍人~'
                                    }
                                  ]}>
                                  <Input />
                                </Form.Item>
                              );
                            default:
                              return null;
                          }
                        }}
                      </Form.Item>

                      <Form.Item
                        {...tailLayout}
                        name="agree"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          1. 此申请表内所填写之一切均属实情，如有虚构或隐瞒事项，视为重大违规，公司有权解除劳动关系。
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    2. 本人同意贵公司向上表提供之有关单位及人士查询有关本人之资料。
                  </Checkbox>
                      </Form.Item>

                      <Form.Item
                        {...tailLayout}
                        shouldUpdate={(prevValues, currentValues) => prevValues.agree !== currentValues.agree}
                      >
                        {({ getFieldValue }) => {
                          return (
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                              disabled={!getFieldValue('agree')}
                              onClick={handleSubmitClick}
                            >
                              提交
                            </Button>
                          );
                        }}
                      </Form.Item>

                    </Form>
                  </Col>
                </Row>
              </div>
            </Content>
          </>
        )}
    </Layout >
  );
};

export default App;
