import React, { useState } from 'react';
import { Form, Input, Button, Modal, Card, Tooltip, Descriptions } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Job = ({ value = [], onChange }) => {
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [modalTitle, setModalTitle] = useState('添加履历');

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      md: {
        offset: 5,
        span: 16,
      },
      xs: {
        offset: 0,
        span: 16,
      }
    },
  };

  const triggerChange = changedValue => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  // 点击添加按钮
  const handleBtnClick = () => {
    form.resetFields();
    setShowForm(true);
  };

  // 关闭弹层
  const handleCancel = () => {
    setShowForm(false);
  };

  // 添加工作履历
  const onFinish = formData => {
    let newJobs = value;

    if (!formData.id) {
      formData.id = Math.random();
      newJobs.push(formData);
    } else {
      for (let i = 0; i < newJobs.length; i++) {
        if (newJobs[i].id === formData.id) {
          newJobs[i] = formData;
        }
      }
    }
    setShowForm(false);
    triggerChange([...newJobs]);
  };

  // 表单验证失败
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  // 编辑工作履历
  const handleEditForm = id => {
    const [formData] = value.filter(item => item.id === id);
    setModalTitle('修改履历');
    form.setFieldsValue(formData);
    setShowForm(true);
  };

  // 删除工作履历
  const handleDelForm = id => {
    const newJobs = value.filter(item => item.id !== id);
    triggerChange([...newJobs]);
  };

  // 工作履历的操作按钮
  const genOpBtn = id => (
    <>
      <Tooltip title="修改">
        <Button type="link" icon={<EditOutlined />} onClick={() => handleEditForm(id)} />
      </Tooltip>
      <Tooltip title="删除">
        <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDelForm(id)} />
      </Tooltip>
    </>
  );

  const jobList = value.map(job => (
    <Card
      hoverable
      size="small"
      title={job.jobTime}
      key={job.id}
      extra={genOpBtn(job.id)}
      style={{ marginTop: 5 }}
    >
      <Descriptions>
        <Descriptions.Item label="公司名称">{job.companyName}</Descriptions.Item>
        <Descriptions.Item label="岗位">{job.job}</Descriptions.Item>
        <Descriptions.Item label="薪酬">{job.salary}</Descriptions.Item>
        <Descriptions.Item label="直属上级">{job.superior}</Descriptions.Item>
        <Descriptions.Item label="联系电话">{job.phone}</Descriptions.Item>
        <Descriptions.Item label="离职原因">{job.reason}</Descriptions.Item>
      </Descriptions>
    </Card>
  ));

  return (
    <span>
      <Button type="primary" icon={<PlusOutlined />} onClick={handleBtnClick}>
        添加
      </Button>
      <Modal
        title={modalTitle}
        visible={showForm}
        destroyOnClose
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          form={form}
          name="job-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="id"
            name="id"
            hidden
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="起始时间"
            name="jobTime"
            rules={[
              {
                required: true,
                message: '请填写起始时间~',
              },
            ]}
          >
            <Input placeholder="2011年9月-2019年12月" />
          </Form.Item>

          <Form.Item
            label="公司名称"
            name="companyName"
            rules={[
              {
                required: true,
                message: '请填写公司名称~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="岗位"
            name="job"
            rules={[
              {
                required: true,
                message: '请填写岗位~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="薪酬"
            name="salary"
            rules={[
              {
                required: true,
                message: '请填写薪酬~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="直属上级"
            name="superior"
            rules={[
              {
                required: true,
                message: '请填写直属上级~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="联系电话"
            name="phone"
            rules={[
              {
                required: true,
                message: '请填写联系电话~',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="离职原因"
            name="reason"
            rules={[
              {
                required: true,
                message: '请填写离职原因~',
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              确定
        </Button>
          </Form.Item>
        </Form>
      </Modal>
      {jobList}
    </span>
  );
};

export default Job;